<template>
  <div id="contacts">
     <v-container fluid >
      <v-container class="mt-5 pt-5">
        <breadcrumbs></breadcrumbs>
      </v-container>
      <v-container>
        <v-row class="align-content-center align-center">
              <v-col cols="12" md="8">
                    <h2 class="text-capitalize mb-4">
                      <v-avatar size="80">
                        <v-img contain :src="require('@/assets/dada.jpg')" class="logo mr-3" :alt="title"></v-img>
                      </v-avatar>
                    {{$t('commons.contacts')}}
                    </h2>
                    <p>
                        <template >
                              <div v-for="(item) in contacts" :key="item.name">
                                <v-btn text color="dark" target="_blank" :href="item.href">
                                    <v-icon class="mr-2">{{item.icon}}</v-icon> {{item.name}}
                                </v-btn> <small>{{item.value}}</small>
                              <br>
                              </div>
                        </template>
                    </p>
              </v-col>
              <v-col cols="12" md="4">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d687.475490790407!2d11.694198088147235!3d46.43082269868889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47786912c30332a5%3A0xb09bd68ecb918abb!2zQ2lhc2EgTsO8!5e0!3m2!1sit!2sit!4v1602118279622!5m2!1sit!2sit" style="width: 100%; height: 350px; border:0;" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              </v-col>
            </v-row>
      </v-container>
     </v-container>
     <title-section 
    fluid 
    class="secondary py-5 light--text" 
    id="titlesection" 
    :title="$t('commons.actions.come-here')" 
    :sub_title="$t('contacts.address.value')"
    content_class="text-center"
    :action="{
        text:$t('commons.actions.come-here'),
        icon:$t('contacts.address.icon'),
        href:$t('contacts.address.href'),
        target:'_blank',
        class:'my-2 text tile display-2', 
        outlined:true, 
        tile:true, 
        color:'light',
        class:'py-5 x-large mx-auto',
      }"
    ></title-section>
    <hero-contact></hero-contact>
  </div>
</template>

<script>
  import TitleSection from "@/components/Layout/Sections/TitleSection"
  import HeroContact from "@/components/Layout/Sections/HeroContact"

  export default {
    components: {
      "title-section": TitleSection,
      "hero-contact": HeroContact
    },
    name: 'Home',
    data: () => ({
      title: process.env.VUE_APP_BASE_NAME,
      contacts: null,
    }),
    created() {
        this.contacts = this.$i18n.t('contacts')
    }
  }
</script>
<style scoped>
#home{
  min-height: 100%;
}
</style>