<template>
     <v-container :fluid="fluid" :class="bgcolor" >
     <v-container class="py-5 my-5">
          <v-row justify="center" align="center">
               <v-col cols="12" md="6" class="text-center">
                    <h1 class="text-uppercase" v-html="title" ></h1>
                    <p v-if="sub_title" v-html="sub_title"></p>
               </v-col>
               <v-col cols="12" md="6" :class="content_class">
                    <p  v-if="content" v-html="content"></p>
                    <v-btn 
                         v-if="action" 
                         :class="action.class" 
                         :color="action.color" 
                         :to="action.to" 
                         :href="action.href" 
                         :outlined="action.outlined" 
                         :tile="action.tile" 
                         :target="action.target" 
                    >
                         {{action.text}}
                              <v-icon v-if="action.icon" class="mx-2">{{action.icon}}</v-icon>
                    </v-btn>
               </v-col>
          </v-row>
     </v-container>
     </v-container>
</template>
<script>
export default {
  name: 'TitleSection',
  props:{
       title:{
            type: String,
            required: false,
       },
       sub_title:{
            type: String,
            required: false,
            default: null
       },
       content:{
            type: String,
            required: false,
       },
       content_class:{
            type: String,
            required: false
       },
       action:{
            type: Object,
            required: false,
            default: null
       },
       fluid:{
            type: Boolean,
            required: false,
            default: true
       },
       bgcolor:{
            type: String,
            required: false,
            default: null
       }
  },
  computed: {
  
  },
  methods:{
       
  }
}
</script>