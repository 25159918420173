<template>
     <section id="contact" class="bg-image vh-50vh">
          <v-container fluid class="bg-grey fill-height ">
          <v-container  class=" fill-height ">
               <v-row class=" fill-height" justify="center" align="center">
                    <v-col cols="12" md="6">
                        <v-img contain :src="require('@/assets/logo-white.svg')" alt="Ciasa Nü" class="logo"></v-img>
                    </v-col>
                    <v-col cols="12" md="6" class="light--text text-center text-md-left">
                         <h2 class="text-capitalize">{{$t('commons.contacts')}}</h2>
                         <p>
                              <template v-for="(item,key) in contacts">
                                   <div :key="key">
                                   <v-btn text color="light"  target="_blank" :href="item.href">
                                        <v-icon class="mr-2">{{item.icon}}</v-icon> {{item.name}}
                                   </v-btn> <small>{{item.value}}</small>
                                   <br>
                                   </div>
                              </template>
                         </p>
                    </v-col>
               </v-row>
          </v-container>
          </v-container>
     </section>
</template>


<script>
 
  export default {
    name: 'HeroContact',
    data: () => ({
      title: process.env.VUE_APP_BASE_NAME,
      contacts: {}
    }),
    created() {
        this.contacts = this.$i18n.t('contacts')
    }
  }
</script>



<style scoped>
.bg-image{
  background-image: url('/img/assets/contacts.jpg');
}
.logo{
  max-height: 85px;
}
.bg-grey {
    background: rgba(72, 72, 72, 0.2);
    background: -webkit-linear-gradient(to bottom, rgba(72, 72, 72, 0.2) , rgba(11, 11, 11, 0.8));
    background: linear-gradient(to bottom, rgba(72, 72, 72, 0.2) , rgba(11, 11, 11, 0.8));
}
.apartment-btn{
  text-transform: none !important;
  height: auto !important;
  font-size: 1.2rem;
}
</style>