var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"contacts"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-container',{staticClass:"mt-5 pt-5"},[_c('breadcrumbs')],1),_c('v-container',[_c('v-row',{staticClass:"align-content-center align-center"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h2',{staticClass:"text-capitalize mb-4"},[_c('v-avatar',{attrs:{"size":"80"}},[_c('v-img',{staticClass:"logo mr-3",attrs:{"contain":"","src":require('@/assets/dada.jpg'),"alt":_vm.title}})],1),_vm._v(" "+_vm._s(_vm.$t('commons.contacts'))+" ")],1),_c('p',[_vm._l((_vm.contacts),function(item){return _c('div',{key:item.name},[_c('v-btn',{attrs:{"text":"","color":"dark","target":"_blank","href":item.href}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")],1),_vm._v(" "),_c('small',[_vm._v(_vm._s(item.value))]),_c('br')],1)})],2)]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('iframe',{staticStyle:{"width":"100%","height":"350px","border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d687.475490790407!2d11.694198088147235!3d46.43082269868889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47786912c30332a5%3A0xb09bd68ecb918abb!2zQ2lhc2EgTsO8!5e0!3m2!1sit!2sit!4v1602118279622!5m2!1sit!2sit","frameborder":"0","allowfullscreen":"","aria-hidden":"false","tabindex":"0"}})])],1)],1)],1),_c('title-section',{staticClass:"secondary py-5 light--text",attrs:{"fluid":"","id":"titlesection","title":_vm.$t('commons.actions.come-here'),"sub_title":_vm.$t('contacts.address.value'),"content_class":"text-center","action":{
      text:_vm.$t('commons.actions.come-here'),
      icon:_vm.$t('contacts.address.icon'),
      href:_vm.$t('contacts.address.href'),
      target:'_blank',
      class:'my-2 text tile display-2', 
      outlined:true, 
      tile:true, 
      color:'light',
      class:'py-5 x-large mx-auto',
    }}}),_c('hero-contact')],1)}
var staticRenderFns = []

export { render, staticRenderFns }